.nav-container {
  display: flex;
  height: calc(100vh - 73px);
  overflow: hidden;
  position: relative;
}

.nav-body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 70px;
  z-index: 0;
}

.header-navigation-container {
  display: flex;
  flex-direction: column;
  background: #504e4e;
  font-size: 14px;
  width: 70px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 0.1s;
  z-index: 10000000;
  user-select: none;
  overflow: hidden auto;
  margin-top: 50px;

  &:hover {
    background: #504e4e;
    box-shadow: 0 0 16px #333;
    width: 250px;

    .nav-entry {
      .drop-down-icon {
        display: inline-block;
      }

      &.small {
        display: flex;
      }
      .nav-label {
        display: block;
        display: flex;
        flex-direction: row;
      }
      .sr-nav-badge {
        position: static;
      }
    }
  }

  .nav-entry {
    position: relative;
    border-top: 1px solid #242939;
    padding: 15px 16px 14px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
    text-decoration: none;

    .drop-down-icon {
      display: none;
      margin-left: 8px;
      font-size: 16px;
    }

    &:hover {
      background-color: #212531;
    }

    &.small {
      display: none;
      padding: 4px 16px 4px 42px;
      border-top: none;
    }

    &.sticky-bottom {
      position: sticky;
      bottom: 0;
      margin-top: auto;
      background-color: #3543bf;
    }
    &.help-center {
      > img {
        width: 22px;
        height: 22px;
      }
      .nav-label {
        text-transform: none;
      }
    }

    .nav-label {
      height: 18px;
      display: none;
      margin-left: 16px;
      color: white;
      white-space: nowrap;
      font-size: 14px;
      text-transform: uppercase;
    }

    .sr-nav-badge {
      width: 18px;
      height: 18px;
      background-color: #ef4f50;
      font-size: 9px;
      font-weight: bold;
      color: #ffffff;
      border-radius: 10px;
      position: absolute;
      top: 7px;
      right: 16px;
      margin-left: 8px;
    }

    &.active {
      padding-left: 21px;
      position: relative;
      background-color: #212531;
      border-left: 3px solid #fe7d32;
      &.small {
        padding-left: 38px;
      }
      img {
        filter: invert(50%) sepia(72%) saturate(505%) hue-rotate(337deg)
          brightness(101%) contrast(106%) !important;
      }
      &::after {
        content: "";
        position: absolute;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #242939;
        left: 100%;
        top: 40%;
      }
    }

    img {
      filter: brightness(2);
      width: 18px;
      height: 18px;
    }
  }
}

.active-navbar-item {
  padding-left: 21px;
  position: relative;
  background-color: #212531;
  border-left: 3px solid #fe7d32;
}

body {
  overflow: hidden;
}
