/* App.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: row;
  }
  
  .popup-content {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    z-index: 1001;
  }
  
  .enquiry-p-tag{
    font-size: "1vw";
    font-weight: "bold";
    margin: 0; 
    padding: 0; 
    text-align: "center"
  }

  .justifyAlignCenter{
    display: flex;
    justify-content: center;
    align-items: center;
  }