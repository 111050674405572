$base-color: #ea6b37;

.dashboard {
  font-size: 24px;
  border-bottom-color: grey;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 13px;
  font-weight: bold;
}

.popup {
  color: rgb(232, 99, 43);
}

.selectionFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}

.padding7 {
  padding: 7px;
}

.row-center {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.add-minus-button {
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 15px;
}

.payment-cell {
  background-color: #9e9e9ebf;
  color: #fff;
}

.pr10-bold {
  padding-right: 10px;
  font-weight: bold;
}

.info-cell {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.booked-button {
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  border-color: $base-color;
  min-width: 272px;
  .booked-button-title {
    color: $base-color;
    font-weight: bold;
  }
  .booked-button-description {
    color: gray;
  }
  &:hover {
    background: $base-color;
    .booked-button-title {
      color: white;
    }
    .booked-button-description {
      color: cornsilk;
    }
  }
}

.booked-footer {
  background-color: $base-color !important;
  color: white !important;
}
