.line-container {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 20px 0;
  }
  
  .line-container::before,
  .line-container::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
  }
  
  .line-container:not(:empty)::before {
    margin-right: 10px;
  }
  
  .line-container:not(:empty)::after {
    margin-left: 10px;
  }
  
  .line-text {
    white-space: nowrap;
    color: #3B98E6;
  }
  