.ui.inverted.dimmer .ui.loader:after {
    border-color: #E65100 transparent transparent !important;
}

.messagetoast {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    -webkit-transition: opacity 0.8s ease-in-out;
}

.opacity0 {
    opacity: 0;
    height: 0;
}